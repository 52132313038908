import React, {useEffect, useState} from 'react';
import {Fade} from 'react-reveal';
import manage from '../assets/manage.png';
import store from '../assets/store.svg';
import './home.css';

function Home() {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  const strings = {
    project:
      'Everything is built around projects & each project comes with three simple tools to manage your work.',
    tasks:
      '1. Task lists: Create lists for organising your work into different phases. Each list will have tasks that let you plan your day-to-day work.',
    docs:
      '2. Docs & Files: This is your repository for storing all docs & files associated with the project.',
    events:
      '3. Events: A simple calendar to keep track of important dates and events.',
  };

  return (
    <div className="flex-center container">
      <h2 className="home-title">
        A complete solution for
        <br />
        effectively managing your work
      </h2>
      <a href="https://apps.apple.com/us/app/manage-tasks-docs-events/id1557080976#?platform=iphone">
        <img src={store} alt="Download" width="200" className="download-btn" />
      </a>

      <p>
        We're not reinventing the wheel, 'Manage' equips you with
        <br />
        the right tools to let you get things done.
      </p>

      <div className="promo-mobile flex-center">
        <img src={manage} alt="Manage app" className="screenshot" />

        <Fade left when={scrollTop <= 100}>
          <div className="talk-bubble tri-right btm-left project">
            <div className="talktext">{strings['project']}</div>
          </div>
        </Fade>

        <Fade right when={scrollTop > 100 && scrollTop <= 325}>
          <div className="talk-bubble tri-right left-top tasks">
            <div className="talktext">{strings['tasks']}</div>
          </div>
        </Fade>

        <Fade left when={scrollTop > 325 && scrollTop <= 500}>
          <div className="talk-bubble tri-right btm-left docs">
            <div className="talktext">{strings['docs']}</div>
          </div>
        </Fade>

        <Fade right when={scrollTop > 500}>
          <div className="talk-bubble tri-right left-top events">
            <div className="talktext">{strings['events']}</div>
          </div>
        </Fade>
      </div>

      <div className="promo-desktop flex-center">
        <img src={manage} alt="Manage app" className="screenshot" />

        <Fade left>
          <div className="talk-bubble tri-right right-top project">
            <div className="talktext">{strings['project']}</div>
          </div>
        </Fade>

        <Fade right>
          <div className="talk-bubble tri-right left-top tasks">
            <div className="talktext">{strings['tasks']}</div>
          </div>
        </Fade>

        <Fade left>
          <div className="talk-bubble tri-right right-top docs">
            <div className="talktext">{strings['docs']}</div>
          </div>
        </Fade>

        <Fade right>
          <div className="talk-bubble tri-right left-top events">
            <div className="talktext">{strings['events']}</div>
          </div>
        </Fade>
      </div>
    </div>
  );
}

export default Home;
