import React, {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './home/home.container';
import MainLayout from './layout/header-content-footer.layout';

// Legal
const Terms = lazy(() => import('./legal/terms.container'));
const Privacy = lazy(() => import('./legal/privacy.container'));
const PageNotFound = lazy(() => import('./home/404.container'));

const WrapMainLayout = (Component, layoutProps) => (props) => (
  <MainLayout {...layoutProps} {...props}>
    <Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </Suspense>
  </MainLayout>
);

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={WrapMainLayout(Home, {showFooter: true})}
        />

        {/*Legal*/}
        <Route
          exact
          path="/terms"
          render={WrapMainLayout(Terms, {showFooter: true})}
        />
        <Route
          exact
          path="/privacy"
          render={WrapMainLayout(Privacy, {showFooter: true})}
        />

        {/*404*/}
        <Route render={WrapMainLayout(PageNotFound)} />
      </Switch>
    </Router>
  );
}

export default App;
