import React from 'react';
import {Link} from 'react-router-dom';
import Logo from '../assets/logo.svg';
import './layout.css';

function MainLayout(props) {
  const {showFooter} = props;

  return (
    <div>
      <div className="header">
        <img className="logo" alt="Manage" src={Logo} height={40} />
        <div className="title-container">
          <Link to={'/'}>
            <h2 className="title">Manage</h2>
          </Link>
        </div>
      </div>

      <div>{React.Children.toArray(props.children)}</div>

      {showFooter && (
        <div className="container">
          <div className="footer flex-center">
            <div className="footer-links">
              <Link to={'/terms'} className="footer-link">
                <p>Terms</p>
              </Link>

              <Link to={'/privacy'} className="footer-link">
                <p>Privacy Policy</p>
              </Link>
            </div>

            <p>© 2021 Techulus. All rights reserved</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default MainLayout;
